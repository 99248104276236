/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import get from "lodash/get"
import {
  Radio,
  Label,
  Input,
  Select,
  Textarea,
  Button,
  Spinner,
} from "@theme-ui/components"
import { useForm } from "react-hook-form"
import queryString from "query-string"
import PropTypes from "prop-types"

import Container from "./container"
import Row from "./row"
import Column from "./column"
import Text from "./text"
let textStates = {}
const Form = ({
  children,
  customStyle,
  formData,
  onSubmit,
  onSuccess,
  noSteps,
  type,
  moveToTopOnSubmit,
}) => {
  const [stepState, setStepState] = React.useState({
    step: 0,
    route: formData.defaultRoute,
    basicDetailsSet: noSteps ? true : false,
    sending: false,
    processing: false,
    sent: false,
    data: {},
    textarray: [],
  })

  const setCharCount = (e, id) => {
    textStates[id] = e.target.value.length
  }

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onChange",
    submitFocusError: false,
  })

  const watches = watch(["form"])
  const validateQuestionCondition = (question, data) =>
    question.conditionQuestion
      ? question.conditionAnswers.includes(
          get(data ? data : watches, `form._${question.conditionQuestion}`)
        )
      : true
  let currentQuestions = get(
    formData.routes,
    `${stepState.route}.questions`,
    []
  ).map(question => (validateQuestionCondition(question) ? question : null))
  if (!noSteps)
    currentQuestions = currentQuestions.slice(
      0,
      stepState.step >= 0 ? stepState.step + 1 : 0
    )

  const renderSelectionButton = (buttonType, currentType, handler) => {
    return (
      <Button
        variant="forms.buttonSelection"
        onClick={() => {
          handler(buttonType)
        }}
        type="button"
        sx={{
          bg: () => (currentType === buttonType ? "primary" : "secondary"),
          textAlign: "left",
        }}
      >
        {buttonType}
      </Button>
    )
  }
  const renderBasicQuestions = () => {
    const styles = {
      label:
        type === "secondary"
          ? {
              mb: 1,
              mt: type === "secondary" ? 3 : 0,
              fontSize: 2,
              fontWeight: "bold",
            }
          : { mb: 1 },
    }
    return (
      <Row customStyle={{ mb: 4 }}>
        <Column size={type === "secondary" ? [12, 12, 6, 6] : [12, 12, 6, 4]}>
          <Label sx={{ ...styles.label }}>First Name</Label>
          <Input
            name="form.basicDetails.firstName"
            ref={register({
              required: "required",
            })}
            variant={
              get(errors, "form.basicDetails.firstName")
                ? "forms.inputError"
                : "forms.input"
            }
          />
        </Column>
        <Column size={type === "secondary" ? [12, 12, 6, 6] : [12, 12, 6, 4]}>
          <Label sx={{ ...styles.label }}>Last Name</Label>
          <Input
            name="form.basicDetails.lastName"
            ref={register({
              required: "required",
            })}
            variant={
              get(errors, "form.basicDetails.lastName")
                ? "forms.inputError"
                : "forms.input"
            }
          />
        </Column>
        <Column size={type === "secondary" ? [12, 12, 6, 12] : [12, 12, 6, 12]}>
          <Label sx={{ ...styles.label }}>Email Address</Label>
          <Input
            name="form.basicDetails.email"
            ref={register({
              required: "required",
              pattern: /^\S+@\S+$/i,
            })}
            variant={
              get(errors, "form.basicDetails.email")
                ? "forms.inputError"
                : "forms.input"
            }
          />
        </Column>
        {!(stepState?.route?.match(/Project homeowner/i) ||
        stepState?.route?.match(/Owner’s rep/i) ||
        stepState?.route?.match(/Student or researcher/i) ||
        stepState?.route?.match(/Job seeker or recruiter/i)) ? (
          <>
            <Column
              size={type === "secondary" ? [12, 12, 6, 12] : [12, 12, 6, 12]}
            >
              <Label sx={{ ...styles.label }}>Company</Label>
              <Input
                name="form.basicDetails.company"
                ref={register({
                  required: "required",
                })}
                variant={
                  get(errors, "form.basicDetails.company")
                    ? "forms.inputError"
                    : "forms.input"
                }
              />
            </Column>
            {/* <Column
              size={type === "secondary" ? [12, 12, 6, 6] : [12, 12, 6, 4]}
            >
              <Label sx={{ ...styles.label }}>Phone</Label>
              <Input
                name="form.basicDetails.phone"
                ref={register({
                  pattern: /^[0-9].+$/i,
                  minLength: 10,
                })}
                variant={
                  get(errors, "form.basicDetails.phone")
                    ? "forms.inputError"
                    : "forms.input"
                }
              />
            </Column> */}
          </>
        ) : null}
      </Row>
    )
  }

  const renderRouteSelector = () => (
    <Row customStyle={{ mb: 4 }}>
      <Column size={[12, 12, 12]}>
        <Text type="h3" customStyle={{ mb: 2 }}>
        What best describes you, in terms of how you would engage with Plant Prefab?
        </Text>
      </Column>
      <Row
        customStyle={{
          mx: 1,
          width: "100%",
        }}
      >
        <Column size={[12, 12, 12]}>
        <Select
          defaultValue={"_"}
          name="Initial Project Type"
          customStyle={{ width: "100%" }}
          onChange={e => changeRoute(e.target.value)}
          ref={register({
            required: true,
            validate: value => value !== "_",
          })}
          variant={
            get(errors, "Initial Project Type") ? "forms.selectError" : "forms.select"
          }
        >
          <option value="_" disabled>
            {formData.dropdownPlaceholderText}
          </option>
          {Object.keys(formData.routes)
            .sort((a, b) => formData.routes[a].order - formData.routes[b].order)
            .map((answer, index) => {
              return (
                <option key={index} value={answer.value}>
                  {answer}
                </option>
              )
            })}
          </Select>
      </Column>
        {/* {Object.keys(formData.routes)
          .sort((a, b) => formData.routes[a].order - formData.routes[b].order)
          .map((buttonType, index) => (
            <Column
              key={index}
              size={[12, 6, 4, 3]}
              customStyle={{
                flexWrap: "wrap",
                my: 2,
                pl: "6px",
                maxWidth: [
                  "100%",
                  "50%",
                  type === "secondary" ? `${100 / 3}%` : "25%",
                  type === "secondary" ? `${100 / 3}%` : "20%",
                ],
                flexBasis: [
                  "100%",
                  "50%",
                  type === "secondary" ? `${100 / 3}%` : "25%",
                  type === "secondary" ? `${100 / 3}%` : "20%",
                ],
              }}
            >
              {renderSelectionButton(buttonType, stepState.route, changeRoute)}
            </Column>
          ))} */}
      </Row>
    </Row>
  )

  const changeRoute = type => {
    setStepState({ ...stepState, step: 0, route: type })
  }

  React.useLayoutEffect(() => {
    const submitGA = () => {
      window &&
        window.gtag &&
        window.gtag("event", "Form submission", {
          event_label: formData.gaEventLabel,
          event_category: "Event",
          non_interaction: true,
        })

      /* For google converion apis */
      window &&
        window.gtag &&
        window.gtag("event", "conversion", {
          send_to: `AW-799114647/${formData.conversionDetail}`,
          value: 1.0,
          currency: "USD",
        })
      window && window.gtag && window.gtag("config", "AW-799114647")
    }
    const sendForm = async data => {
      let requestData = {
        ...formData.defaultFields,
      }
      const referralLink =
        type === "secondary"
          ? window.location.href
          : queryString.parse(window.location.search).ref
      if (formData.referralLinkField && referralLink) {
        requestData[formData.referralLinkField] = referralLink
      }
      if (formData.basicQuestions)
        requestData = {
          ...requestData,
          first_name: data.form.basicDetails.firstName,
          last_name: data.form.basicDetails.lastName,
          email: data.form.basicDetails.email,
          company: data.form.basicDetails.company ||  requestData.company,
          phone: data.form.basicDetails.phone,
        }
      if (formData.routes[stepState.route].hiddenFields) {
        requestData = {
          ...requestData,
          ...formData.routes[stepState.route].hiddenFields,
        }
      }
      if (localStorage.getItem("parameter")) {
        let urlformdata = JSON.parse(localStorage.getItem("formObj"))
        requestData = {
          ...requestData,
          ...urlformdata,
        }
      }
      if (onSubmit) {
        // Converting to JSON
        // .then(response => response.json())

        // // Displaying results to console
        // .then(json => console.log("resdat",json));
        onSubmit(requestData)
      }
      submitGA()
      textStates = {}
      let response
      let res

      if (localStorage.getItem("parameter")) {
        let urlformdata = JSON.parse(localStorage.getItem("formObj"))
        if(urlformdata && urlformdata['gclid']) {
          requestData['00N8c00000dpAuR'] =  urlformdata['gclid']
        }
      }

      if (formData.method === "GET") {
        if (formData.callOnlyloggerUrl) {
          // logger api call

          res = await fetch(
            queryString.stringifyUrl(
              {
                url: "https://api.plantprefab.com/v1/logger/",
                query: requestData,
              },
              { encode: true, sort: false },
              {
                method: "GET",
                // headers: {
                //   'Content-Type': 'text/html',
                //   'access-control-allow-origin': '*',
                // },
              }
            )
          )
            .then(response => response.json())
            .catch(error => console.log("Request failed", error))
        } else {
          // logger api call

          res = await fetch(
            queryString.stringifyUrl(
              {
                url: "https://api.plantprefab.com/v1/logger/",
                query: requestData,
              },
              { encode: true, sort: false },
              {
                method: "GET",
                // headers: {
                //   'Content-Type': 'text/html',
                //   'access-control-allow-origin': '*',
                // },
              }
            )
          )
            .then(response => response.json())
            .catch(error => console.log("Request failed", error))

          // agile Crm api call

          // response = await fetch(
          //   queryString.stringifyUrl(
          //     { url: formData.url, query: requestData },
          //     { encode: true, sort: false }
          //   ),
          //   {
          //     method: "GET",
          //   }
          // )

          if(formData.SalesforceUrl){
            let requestOptions = {
              method: 'POST',
              body: JSON.stringify(requestData)
            };
            
           
            let resp = await fetch(formData.SalesforceUrl, requestOptions);
            let data = await resp.json();
            response = data;
          }
        }

        // Promise.all([res,response]);
      }

      if (formData.callOnlyloggerUrl) {
        // console.log("res-->",res);
        if (res === "Success!") {
          if (onSuccess) onSuccess(requestData)
          setStepState({ ...stepState, sending: false, sent: true })
        }
      } else {
        // if (response.status === 200) {
        if (response === "SFDC Post Successful!") {
          if (onSuccess) onSuccess(requestData)
          setStepState({ ...stepState, sending: false, sent: true })
        }
      }
    }

    if (stepState.sending && !stepState.processing) {
      sendForm(stepState.data)
      setStepState({ ...stepState, processing: true })
    }
  }, [stepState, formData, onSubmit, onSuccess, type])

  const handleNewsletterSubmit = data => {
    setStepState({ ...stepState, sending: true, data })
  }
  let lineBreak = formData.thankYouMessage.split('<br/>');
  return (
    <Container customStyle={{ ...customStyle }}>
      {!(stepState.sent || stepState.sending) ? children : null}
      {stepState.sent ? (
        <>
        {lineBreak.length > 1 ?
          <>
          <Text type="h2">{lineBreak[0]}</Text>
          <Text type="p">{lineBreak[1]}</Text>
          </>
          :
        <Text type="h2">{formData.thankYouMessage}</Text>
        }
        </>
      ) : stepState.sending ? (
        <Spinner />
      ) : (
            <>
              <div sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                <Text type="h2" style={{lineHeight:"1.2"}}>Let’s stay in touch</Text>
              </div>
              <br />
              <form onSubmit={handleSubmit(handleNewsletterSubmit)}>
                {renderRouteSelector()}
                {renderBasicQuestions()}
                <Button
                  type="submit"
                  variant="forms.buttonChevron"
                  sx={{ background: stepState.route ? null : "#888" }}
                  disabled={!stepState.route}
                >
                  {formData.submitButtonText}
                </Button>
              </form>
            </>
        
      )}
    </Container>
  )
}
Form.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.object,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
}
export default Form
