/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Text from "./text"

const PageLink = ({
  children,
  customStyle,
  link,
  text,
  type,
  target,
  ...restProps
}) => {
  const pageLinkStyle = {
    color: "secondary",
    fontSize: 2,
    textDecoration: "none",
  }
  const renderPageLinkContent = () =>
    children ? (
      children
    ) : text ? (
      <Text customStyle={{ color: "inherit", fontSize: "inherit" }}>
        {text}
      </Text>
    ) : null

  return type && type === "internal" ? (
    // <Link
    //   sx={{ pr: 5, ...pageLinkStyle, ...customStyle }}
    //   to={link}
    //   {...restProps}
    // >
    //   {renderPageLinkContent()}
    // </Link>
    <a
      sx={{ pr: 5, ...pageLinkStyle, ...customStyle }}
      href={link}
      {...restProps}
    >
      {renderPageLinkContent()}
    </a>
  ) : (
    <a
      sx={{ pr: 5, ...pageLinkStyle, ...customStyle }}
      href={link}
      rel="noopener noreferrer"
      target={target || "_blank"}
      {...restProps}
    >
      {renderPageLinkContent()}
    </a>
  )
}

PageLink.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.object,
  link: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(["internal", "external"]),
  target: PropTypes.string,
}

export default PageLink
