/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import theme from "../gatsby-plugin-theme-ui"
import PageLink from "./page-link"

const CTA = ({ children, customStyle, link, linkType, type, onClick }) => {
  const componentType = type || "primary"
  const typeStyleList = {
    primary: { chevron: { right: 20 } },
    secondary: { chevron: { right: 20 } },
    tertiary: { chevron: { right: 15 } },
    alt: {
      bg: "transparent",
      borderColor: "primary",
      color: "primary",
      chevron: { right: 15 },
    },
  }

  return (
    <PageLink
      customStyle={{
        bg: componentType === "alt" ? "transparent" : "primary",
        borderRadius: 9999,
        color: "white",
        cursor: "pointer",
        display: "inline-block",
        fontFamily: "body",
        lineHeight: 1,
        position: "relative",
        textAlign: "left",
        ":hover": {
          bg:
            (typeStyleList[componentType] && typeStyleList[componentType].bg) ||
            "secondary",
          borderColor:
            type !== "alt"
              ? (typeStyleList[componentType] &&
                  typeStyleList[componentType].borderColor) ||
                null
              : "secondary",
          color:
            type !== "alt"
              ? (typeStyleList[componentType] &&
                  typeStyleList[componentType].color) ||
                null
              : "secondary",
        },
        ...theme.buttons[componentType],
        ...customStyle,
      }}
      link={link}
      type={
        linkType !== "dynamic"
          ? linkType
          : /^https?:\/\/.*$/.test(link)
          ? "external"
          : "internal"
      }
      onClick={onClick}
    >
      <span>{children}</span>
      <span
        sx={
          typeStyleList[componentType] &&
          typeStyleList[componentType].chevron &&
          Object.assign(
            {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            },
            typeStyleList[componentType].chevron
          )
        }
      >
        {">"}
      </span>
    </PageLink>
  )
}
CTA.defaultProps = {
  linkType: "dynamic",
}
CTA.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.object,
  link: PropTypes.string,
  linkType: PropTypes.oneOf(["internal", "external", "dynamic"]),
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "alt"]),
}

export default CTA
