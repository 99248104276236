/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import React from "react"

import { Button } from "@theme-ui/components"
import Column from "../components/column"
import Container from "../components/container"
import PageLink from "./page-link"
import Row from "../components/row"
import Text from "../components/text"
import theme from "../gatsby-plugin-theme-ui/index"

import CTA from "./cta"

import plantPrefabLogo from "../images/PP-Logo-Full-Color-TM.svg"
import facebookLogo from "../images/facebook-slim.svg"
import twitterLogo from "../images/twitter-slim.svg"
import linkedinLogo from "../images/linkedin-slim.svg"
import instagramLogo from "../images/instagram-slim.svg"
// import youtubeLogo from "../images/YouTube-Icon-Almost-Black-Logo_wine.svg"
import youtubeLogo from "../images/youtube1.svg"
import NewsletterForm from "./newsletter-form"
import BCorpLogoBlack from "../images/BCorpLogoBlack.svg"

const socialLinks = [
  { url: "https://www.facebook.com/plantprefab/", img: facebookLogo },
  { url: "https://twitter.com/plantprefab", img: twitterLogo },
  { url: "https://www.linkedin.com/company/plant-prefab/", img: linkedinLogo },
  {
    url: "https://www.youtube.com/c/plantprefab/",
    img: youtubeLogo,
    sx: {
      height: "22px",
      filter: "contrast(35%)",
    },
  },
  {
    url: "https://www.instagram.com/plantprefab/",
    img: instagramLogo,
    sx: { top: "1px" },
  },
]

const footerListItemStyle = {
  mr: 3,
  mb: 0,
  ":last-child": { mr: 0 },
}
const footerLinkList = [
  {
    section: "Plant Prefab",
    id: "PlantPrefabColumn",
    links: [
      // {
      //   name: "Advantages",
      //   id: "AdvantagesLink",
      //   href: "/advantages",
      // },
      // {
      //   name: "Models",
      //   id: "ModelsLink",
      //   href: "/models",
      // },
      {
        name: "Capabilities",
        id: "CapabilitiesLink",
        href: "/capabilities",
      },
      {
        name: "Portfolio",
        id: "PortfolioLink",
        href: "/projects",
      },
      // {
      //   name: "FAQ",
      //   id: "f6def2fc-5ba4-5e0e-abbb-2d5e60d03ea0",
      //   href: "/faq",
      // },
    ],
  },
  // {
  //   section: "Services",
  //   id: "ServicesColumn",
  //   links: [
  //     // {
  //     //   name: "LivingHomes",
  //     //   id: "LivingHomesLink",
  //     //   href: "/livinghomes",
  //     // },
  //     {
  //       name: "Design",
  //       id: "CustomDesignLink",
  //       // href: "/custom-design",
  //       href: "/plant-design-studio"
  //     },
  //     {
  //       name: "Fabrication",
  //       id: "CustomBuildLink",
  //       href: "/custom-construction",
  //     },
  //     // {
  //     //   name: "Wildfire Rebuild",
  //     //   id: "WildfireRebuildLink",
  //     //   href: "/wildfire-help",
  //     // },
  //   ],
  // },
  // {
  //   section: "Working With Us",
  //   id: "WorkingWithUsColumn",
  //   links: [
  //     // {
  //     //   name: "Homebuyers",
  //     //   id: "HomebuyersLink",
  //     //   href: "/homebuyers",
  //     // },
  //     {
  //       name: "Developers",
  //       id: "DevelopersLink",
  //       href: "/developers",
  //     },
  //     {
  //       name: "Architects",
  //       id: "ArchitectsLink",
  //       href: "/architects-and-designers",
  //     },
  //   ],
  // },
  {
    section: "Company",
    id: "AboutUsColumn",
    links: [
      {
        name: "About",
        id: "About",
        href: "/about",
      },
      {
        name: "Design Studio",
        id: "DesignStudio",
        href: "/plant-design-studio",
      },
      {
        name: "Careers",
        id: "Careers",
        href: "/jobs",
      },
      {
        name: "Contact",
        id: "Contact",
        href: "/contact",
      },
      // {
      //   name: "Design Partners",
      //   id: "DesignPartners",
      //   href: "/architects",
      // },
      // {
      //   name: "Tours & Events",
      //   id: "ToursEventsLink",
      //   href: "/tours-and-events",
      // },
    ],
  },
  {
    section: "News",
    id: "NewsColumn",
    links: [
      {
        name: "Blog",
        id: "Blog",
        href: "/blog",
      },
      {
        name: "In the Media",
        id: "InTheMedia",
        href: "/in-the-news",
      },
      {
        name: "Press Center",
        id: "PressCenter",
        href: "/press-center",
      },
    ],
  },
]

const Footer = () => {
  const [showContact, setShowContact] = React.useState(false)

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }
  
  React.useEffect(() => {
    if (checkIfWindow) {
      let params = new URLSearchParams(window.location.search);
       let urlCheck = params.get("newslettersignup");
       setShowContact((urlCheck && urlCheck === "true"))
    }
  }, []);
 
  
  const Logo = ({ customStyle }) => (
    <Column
      size={[12, 12, 12, 3]}
      customStyle={{ minWidth: 260, ...customStyle }}
    >
      <PageLink
        link="/"
        type="internal"
        customStyle={{ mb: theme => theme.spacing.vertical.sm }}
      >
        <img
          sx={{
            height: 59,
            width: 136,
            m: 0,
          }}
          src={plantPrefabLogo}
          alt="Plant Prefab Logo"
        />
      </PageLink>
      {/* <Text
        customStyle={{
          fontWeight: "bold",
          mb: 36,
          mt: 20,
        }}
      >
        Plant Prefab
      </Text> */}
      <div sx={{mt:36}}>
        <CTA
          link="/contact"
          linkType="internal"
          type="primary"
          customStyle={{ pr: 80 }}
        >
          Contact Us
        </CTA>
        <Button
          sx={{
            ...theme.forms.buttonChevron,
            fontSize: 14,
            pr: 7,
            py: "5px",
            pl: 19,
            mt: 26,
            display: "flex",
            justifyContent: "space-between",
            width: 190,
            color: "primary",
            border: theme => `2px solid ${theme.colors.primary}`,
            background: "transparent",
            whiteSpace: "nowrap",
            ":hover": {
              border: theme => `2px solid ${theme.colors.secondary}`,
              color: "secondary",
            },
          }}
          onClick={() => setShowContact(true)}
        >
          Join Our Newsletter
        </Button>
        <div
          sx={{
            mt: 4,
            width: 190,
            display: ["flex", "flex", "flex", "none"],
            justifyContent: "space-between",
          }}
        >
          <SocialLinks type="secondary" />
        </div>
      </div>
    </Column>
  )

  const BCrop = ({ customStyle }) => (
    <Column
      size={[6, 4, 4, null]}
      customStyle={{ width: "max-content", ...customStyle }}
    >
      <PageLink
        link="https://www.bcorporation.net/en-us/find-a-b-corp/company/plant-prefab"
        type="external"
        // customStyle={{ mb: theme => theme.spacing.vertical.sm }}
      >
        <img
          sx={{
            height: 120,
            // float:"right",
            // width: 136,
            m: 0,
          }}
          src={BCorpLogoBlack}
          alt="B Crop Logo Black"
        />
      </PageLink>
    </Column>
  )

  const SocialLinks = ({ type }) =>
    socialLinks.map((link, index) => (
      <a
        key={index}
        href={link.url}
        sx={{ mr: 25, ":last-of-type": { mr: 0 } }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          sx={{
            height: 25,
            filter:
              "invert(17%) sepia(19%) saturate(59%) hue-rotate(315deg) brightness(99%) contrast(92%)",
            position: "relative",
            ...link.sx,
          }}
          src={link.img}
          alt=""
        ></img>
      </a>
    ))

  return (
    <footer>
      <div sx={{ backgroundColor: "muted", overflowX: "hidden" }}>
        {showContact && (
          <NewsletterForm onClose={() => setShowContact(false)} />
        )}
        <Container>
          <Row
            customStyle={{
              py: theme => [
                theme.spacing.vertical.md,
                theme.spacing.vertical.md,
                theme.spacing.vertical.md,
                theme.spacing.vertical.lg,
              ],
            }}
          >
            <Logo
              customStyle={{ display: ["none", "none", "none", "block"] }}
            />
            
            {footerLinkList.map(column => (
              <Column size={[6, 4, 3, 2]} key={column.id} customStyle={{
                maxWidth: [
                  null,
                  null,
                  theme => theme.spacing.vertical.xl,
                  theme => theme.spacing.vertical.xl,,
                ],
              }}>
                <Text
                  customStyle={{
                    color: "#A6A6A6",
                    mt: [
                      theme => theme.spacing.vertical.sm,
                      theme => theme.spacing.vertical.sm,
                      theme => theme.spacing.vertical.sm,
                      0,
                    ],
                    textTransform: "uppercase",
                  }}
                >
                  {column.section}
                </Text>
                <ul sx={{ listStyle: "none", m: 0 }}>
                  {column.links.map(link => (
                    <li sx={{ m: 0 }} key={link.id}>
                      <Link sx={{ textDecoration: "none" }} to={link.href}>
                        <Text
                          customStyle={{
                            fontSize: 2,
                            // mt: theme => theme.spacing.vertical.sm,
                            mt: "15px",
                          }}
                        >
                          {link.name}
                        </Text>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Column>
            ))}
            <BCrop
              customStyle={{
                display: ["block", "block", "block", "block"],
                float: "right",
                textAlign:[null,null, null, "end"],
                mt: [
                  theme => theme.spacing.vertical.sm,
                  theme => theme.spacing.vertical.sm,
                  theme => theme.spacing.vertical.sm,
                  0,
                ],
              }}
            />
            <Logo
              customStyle={{
                display: ["block", "block", "block", "none"],
                mt: theme => theme.spacing.vertical.md,
              }}
            />
          </Row>
        </Container>
      </div>
      <div sx={{ backgroundColor: theme => theme.colors.backgrounds.primary }}>
        <Container>
          <Row
            customStyle={{
              py: 3,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div
                sx={{
                  display: "flex",
                  listStyle: "none",
                  margin: 0,
                }}
              >
                <Text customStyle={{ ...footerListItemStyle, fontSize: 1 }}>
                  © {new Date().getFullYear()} Plant Prefab, Inc. All rights
                  reserved.
                </Text>
                <Link
                  sx={{ ...footerListItemStyle, textDecoration: "none" }}
                  to="/privacy-policy"
                >
                  <Text customStyle={{ fontSize: 1 }}>Privacy Policy</Text>
                </Link>
              </div>
            </div>
            <div sx={{ display: ["none", "none", "none", "block"] }}>
              <SocialLinks />
            </div>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
