/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import queryString from "query-string"

import { mediaQueryList } from "../helpers"

import { useIsClient } from "../hooks/use-is-client"

import Fonts from "./fonts"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const NavigationContext = React.createContext({})

const Layout = ({ children, location }) => {
  let showNavFooter = true
  if (
    location.pathname == "/tahoe-campaign/" ||
    location.pathname == "/tahoe-campaign" ||
    location.pathname == "/bisnow-23/" ||
    location.pathname == "/bisnow-23"  ||
    location.pathname == "/wom-23/" ||
    location.pathname == "/wom-23"  ||
    location.pathname == "/builders-23/" ||
    location.pathname == "/builders-23" ||
    location.search == "?leadsourcedetail=Outbound+Contact+Form"||
    location.search =="?leadsourcedetail=Outbound%20Contact%20Form"
  )
    showNavFooter = false
  const isClient = useIsClient()
  const host = isClient && window.location.hostname
  React.useEffect(() => {
    if (window.location.host.includes("livinghomes.net")) {
      window.location.host = "plantprefab.com"
    }
  }, [host])

  const [navigationOpen, setNavigationState] = React.useState(false)
  const navigationOpenClassName = "navigation-open"

  React.useEffect(() => {
    if (navigationOpen) {
      document.documentElement.classList.add(navigationOpenClassName)
    } else {
      document.documentElement.classList.remove(navigationOpenClassName)
    }

    return () => {
      document.documentElement.classList.remove(navigationOpenClassName)
    }
  }, [navigationOpen])

  return (
    <NavigationContext.Provider value={[navigationOpen, setNavigationState]}>
      <Fonts />
      <Global
        styles={css`
          .${navigationOpenClassName} {
            overflow: hidden;
          }

          ${mediaQueryList()[2]} {
            .${navigationOpenClassName} {
              overflow: auto;
            }
          }
        `}
      />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          "& *:focus": {
            outline: 0,
          },
        }}
      >
        <Header showNavFooter={showNavFooter} />
        <div>
          <main>{children}</main>
        </div>
        {showNavFooter && <Footer />}
      </div>
    </NavigationContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { NavigationContext }

export default Layout
