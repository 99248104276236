import React from "react"

export const useIsClient = () => {
  const [isClient, setIsClient] = React.useState(false)

  React.useEffect(() => {
    setIsClient(typeof window === "object")
  }, [])

  return isClient
}
