/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Flex } from "@theme-ui/components"

import { getResponsiveValueList, mergeStyle } from "../helpers"

const Column = ({ customStyle, children, noGutters, size, margin ,maxWidth}) => {
  const getColumnSize = () => {
    const sizeList = size
      ? size.map(sizeValue =>
          sizeValue ? `${(parseInt(sizeValue, 10) * 100) / 12}%` : 0
        )
      : null

    return {
      flexBasis: sizeList,
      maxWidth:
        sizeList && sizeList.indexOf(0) !== -1 // "0" works for flexBasis but maxWidth has to be set to "initial".
          ? sizeList.map(sizeValue => (sizeValue === 0 ? "initial" : sizeValue))
          : sizeList,
    }
  }
  const gutterList = getResponsiveValueList({
    breakpointValue: 0,
    defaultValue: theme => theme.spacing.horizontal / 2,
    responsiveList: noGutters,
  })
  const columnStyle = {
    flex: 1,
    flexDirection: "column",
    px: gutterList,
    ...getColumnSize(),
  }

  return <Flex sx={mergeStyle(customStyle, columnStyle)} style={{margin : (margin) ? margin : "",maxWidth }}>{children}</Flex>
}

Column.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.object,
  noGutters: PropTypes.arrayOf(PropTypes.bool),
  size: PropTypes.arrayOf(PropTypes.number),
}

export default Column
