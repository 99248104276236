/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Flex } from "@theme-ui/components"

import { getResponsiveValueList, mergeStyle } from "../helpers"

const Row = ({ children, customStyle, noGutters }) => {
  const gutterList = getResponsiveValueList({
    breakpointValue: 0,
    defaultValue: theme => `-${theme.spacing.horizontal / 2}px`,
    responsiveList: noGutters,
  })

  return (
    <Flex sx={mergeStyle(customStyle, { flexWrap: "wrap", mx: gutterList })}>
      {children}
    </Flex>
  )
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.object,
  noGutters: PropTypes.arrayOf(PropTypes.bool),
}

export default Row
