import React from "react"
import { Global, css } from "@emotion/core"

import helveticaNeueBoldWoff from "../fonts/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff"
import helveticaNeueBoldWoff2 from "../fonts/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2"
import helveticaNeueItalicWoff from "../fonts/e7c4b231-76ad-47c7-a54b-5d84dcd78d0f.woff"
import helveticaNeueItalicWoff2 from "../fonts/21c44514-f4d6-4cff-a5de-e4cac5e61aff.woff2"
import helveticaNeueLightWoff from "../fonts/50ac1699-f3d2-47b6-878f-67a368a17c41.woff"
import helveticaNeueLightWoff2 from "../fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2"
import helveticaNeueMediumWoff from "../fonts/050b1948-f226-4d20-a65a-15d8ed031222.woff"
import helveticaNeueMediumWoff2 from "../fonts/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2"
import helveticaNeueRomanWoff from "../fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff"
import helveticaNeueRomanWoff2 from "../fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2"

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-display: swap;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 300;
        src: url(${helveticaNeueLightWoff2}) format("woff2"),
          url(${helveticaNeueLightWoff}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 400;
        src: url(${helveticaNeueRomanWoff2}) format("woff2"),
          url(${helveticaNeueRomanWoff}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        src: url(${helveticaNeueMediumWoff2}) format("woff2"),
          url(${helveticaNeueMediumWoff}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Helvetica Neue;
        font-style: italic;
        font-weight: 400;
        src: url(${helveticaNeueItalicWoff}) format("woff2"),
          url(${helveticaNeueItalicWoff2}) format("woff");
      }

      @font-face {
        font-display: swap;
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 700;
        src: url(${helveticaNeueBoldWoff}) format("woff2"),
          url(${helveticaNeueBoldWoff2}) format("woff");
      }
    `}
  />
)

export default Fonts
