/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "./modal"
import NewsletterSignup from './newsletter-signup-form';

const NewsletterForm = ({ onClose }) => {
  const data = useStaticQuery(graphql`
    query newsletterFormQuery {
      contentfulSection(slug: { eq: "page-section-footer-contact-form" }) {
        content {
          internal {
            content
          }
        }
      }
    }
  `)
  const formData = JSON.parse(data.contentfulSection.content.internal.content)
  if (formData.detailField)
    formData.defaultFields = {
      ...formData.defaultFields,
      [formData.detailField]: `Newsletter Signup`,
    }
  if (formData.conversionDetail)
    formData.conversionDetail = "E3ZxCL__jNUBEJeLhv0C"
  if (formData.gaEventLabel)
    formData.gaEventLabel = `Newsletter Signup Form Submit`
  return (
    <Modal
      customStyle={{
        maxWidth: [null, null, null, 700],
        boxSizing: "border-box",
      }}
      onClose={onClose}
    >
      <NewsletterSignup formData={formData} noSteps={true} type="secondary"></NewsletterSignup>
    </Modal>
  )
}

export default NewsletterForm
