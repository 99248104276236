/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "./container"
import Text from "./text"

const Modal = ({ title,text, children, onClose, customStyle }) => {
  return (
    <div
      sx={{
        overflowX: "hidden",
        overflowY: "auto",
        position: "fixed",
        display: "grid",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        p: [0, 0, 0, 5],
        background: "rgba(0,0,0,0.5)",
        zIndex: 9999,
      }}
    >
      <div
        sx={{
          width: "100%",
          // height: "100%",
          // px: [0, 0, 0, "30px"],
          // pt: "20px",
          pb: 2,
          background: "#fff",
          boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
          ...customStyle,
        }}
      >
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // mb: 3,
            // mx: ["20px", "20px", "20px", 0],
          }}
        >
          <Text 
          // type="h3"
          type="h2" style={{lineHeight:"1.2"}}
          >{ (title === "Download Plans" || title === "Download" ) ? "" : title || "" }</Text>
          <Text
            type="h3"
            customStyle={{ color: "primary", cursor: "pointer" }}
            {...(onClose && { onClick: onClose })}
          >
            &#10005;
          </Text>
        </div>

        { (title === "Download Plans" || title === "Download" ) &&
          <Container customStyle={{ ...customStyle }}>
            <div sx={{ width: "100%", height: "100%", overflow: "auto" }}>
              {/* <Text type="h3">{title || ""}</Text> */}
              <Text type="h2" style={{lineHeight:"1.2"}}>{title || ""}</Text>
              <Text type="p">{text || ""}</Text>
            </div>
            <br />
          </Container>
        }
        <div sx={{ width: "100%", height: "100%", overflow: "auto" }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
